<template>
  <Modal
    v-model="removeUserModal"
    sm
    @ok="SEND_ACTIVE_USER_ACTION"
    title="Kullanıcıyı Düzenleyin"
  >
    <section class="vid-title-sec">
      <div
        class="alert"
        :class="`alert-${alertBox.variant}`"
        v-if="alertBox.status"
      >
        {{ alertBox.message }}
      </div>
      <div class="container">
        <span v-if="user.isActive === 1">
          {{ $t("removeUserDoYouWant") }}
        </span>
        <span v-else>
          {{ $t("activeUserDoYouWant") }}
        </span>
      </div>
    </section></Modal
  >
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  props: {
    user: {
      default: () => {},
    },
  },
  data() {
    return {
      removeUserModal: false,
      alertBox: { status: false },
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    removeUserModal(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    async SEND_ACTIVE_USER_ACTION(loading) {
      loading(true);
      const fd = new FormData();
      fd.append("isActive", this.user.isActive === 1 ? 0 : 1);
      if (this.user.isActive === 1)
        this.alertBox = await this.$store.dispatch(
          "SEND_TO_REMOVE_CUSTOMER_LIST",
          {
            id: this.user.id,
          }
        );
      else
        this.alertBox = await this.$store.dispatch(
          "SEND_UPDATE_CUSTOMER_DETAIL",
          {
            fd,
            form: {
              isActive: this.user.isActive === 1 ? 0 : 1,
              id: this.user.id,
            },
          }
        );
      loading(false);
      this.removeUserModal = false;
      setTimeout(() => {
        if (this.alertBox.variant === "success") {
          this.$store.commit("SET_CUSTOMER_LIST_IS_LOADING", true);
        }
        setTimeout(() => {
          this.$store.commit("SET_CUSTOMER_LIST_IS_LOADING", false);
        }, 1000);
      }, 2500);
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "remove-user")
          this.removeUserModal = true;
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>

<style></style>
