<template>
  <div class="container">
    <NoAuth v-if="getActiveUser.roleId === 3 && getSettings.departmentId === 2">
    </NoAuth>
    <div v-else-if="getCustomerListIsLoading">
      <PageLoading></PageLoading>
    </div>
    <div v-else>
      <HeaderCard @change:tab="SET_TAB" :user="getCustomer"></HeaderCard>
      <Tabs :tab="tab" @change:tab="SET_TAB" :fields="fields"></Tabs>
      <component :user="getCustomer" :is="tab.path"></component>
    </div>
  </div>
</template>

<script>
import HeaderCard from "./HeaderCard.vue";
import Tabs from "./Tabs.vue";
import PageLoading from "../Shared/PageLoading.vue";
import NoAuth from "../Shared/NoAuth.vue";
export default {
  components: {
    NoAuth,
    HeaderCard,
    Tabs,
    PageLoading,
    Detail: () => import("./Detail.vue"),
    SendMail: () => import("./SendMail.vue"),
    BoughtItems: () => import("./BoughtItems.vue"),
    Comments: () => import("./Comments.vue"),
    UploadedItems: () => import("./UploadedItems.vue"),
    QuizzesOfUser: () => import("./QuizzesOfUser.vue"),
    CourseStats: () => import("./CourseStats.vue"),
  },
  data() {
    return {
      id: 0,
      tab: {},
      fields: [
        { title: this.$t("generalInfo"), path: "Detail" },
        { title: this.$t("sendMail"), path: "SendMail" },
        { title: this.$t("usersBought"), path: "BoughtItems" },
        // { title: this.$t("uploadItems"), path: "UploadedItems" },
        { title: this.$t("comments"), path: "Comments" },
        { title: this.$t("joinedQuizzes"), path: "QuizzesOfUser" },
        { title: this.$t("courseStats"), path: "CourseStats" },
      ],
    };
  },
  computed: {
    getCustomerListIsLoading() {
      return this.$store.state.CustomerList.customerListIsLoading;
    },
    getCustomer() {
      const customerList = this.$store.state.CustomerList.customerList.find(
        (p) => p.id === Number(this.id)
      );
      if (customerList) return customerList;
      else return { id: -1 };
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
    checkValidRole() {
      return (
        this.getActiveUser.roleId === 3 && this.getSettings.departmentId === 2
      );
    },
  },

  methods: {
    SET_TAB(tab) {
      this.tab = tab;
    },
    // GET CUSTOMER TO LIST
    GET_CUSTOMER_TO_LIST() {
      try {
        this.id = this.$route.params.id;

        if (this.checkValidRole) return;
        this.$store.dispatch("GET_CUSTOMER_TO_LIST", {
          id: Number(this.id),
        });
      } catch (error) {
        this.$router.push("/error");
      }
    },
  },
  mounted() {
    this.GET_CUSTOMER_TO_LIST();
    this.tab = { title: this.$t("generalInfo"), path: "Detail" };
  },
};
</script>

<style></style>
