<template>
  <UserList></UserList>
</template>

<script>
import UserList from "../components/UserList";
export default {
  components: { UserList },
};
</script>

<style></style>
