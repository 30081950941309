<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start align-items-center">
          <span
            class="d-none bg-primary d-md-flex avatar-text"
            v-if="user.fullname"
            >{{ user.fullname.charAt(0) }}</span
          >
          <span v-else class="avatar-text">
            <img src="/android-chrome-192x192.png" alt="" />
          </span>
          <div class="ml-4">
            <h4>
              <i class="icon-user"></i> {{ user.fullname }}
              <img
                :src="`https://flagicons.lipis.dev/flags/4x3/${
                  user?.langCode == 'undefined'
                    ? 'tr'
                    : user.langCode?.toLowerCase() || 'tr'
                }.svg`"
                :title="user.langCode?.toLowerCase() || 'tr'"
                width="30"
                :alt="user.langCode"
              />
            </h4>
            <p>{{ formatter(new Date(user.createdAt)) }}</p>
          </div>
        </div>
        <div>
          <button
            @click="
              $store.commit('SET_MAIL_DRAWER', {
                status: true,
                form: {
                  to: user.email,
                  bcc: '',
                  subject: ``,
                  content: ``,
                  files: [],
                },
              })
            "
            class="btn btn-pill donate m-1"
          >
            {{ $t("sendMessage") }}
          </button>
          <button
            @click="
              $router.replace({
                query: {
                  ...$route.query,
                  modal: 'remove-user',
                },
              })
            "
            class="btn btn-pill m-1"
          >
            {{
              user.isActive === 1
                ? user.roleId === 3
                  ? $t("removeInstructor")
                  : $t("removeUser")
                : $t("reActiveUser")
            }}
          </button>
        </div>
      </div>
      <hr />
      <div
        class="alert mb-2"
        :class="`alert-${alertBox.variant}`"
        v-if="alertBox.status"
      >
        {{ alertBox.message }}
      </div>
      <div class="mt-4 user-detail-header">
        <span>
          {{ $t("email") }}:
          <a class="email-link" :href="`mailto:${user.email}`">{{
            user.email
          }}</a></span
        >
        <span class="border-left">
          {{ $t("phone") }}:
          <a class="tel-link" :href="`tel:${user.phone}`">{{
            user.phone
          }}</a></span
        >
        {{ $t("situation") }}:
        <span
          class="badge py-1"
          :class="{
            'badge-success': user.isActive === 1,
            'badge-danger': user.isActive === 0,
          }"
          >{{ user.isActive === 1 ? $t("active") : $t("closed") }}</span
        >
        <span class="border-left">
          {{ $t("approval") }}:
          <span v-if="user.isVerify === 0">
            <button
              class="btn btn-pill btn-success m-1"
              @click="SET_CHANGE_APPROVAL(1)"
            >
              {{ $t("approve") }}</button
            ><button
              class="btn btn-pill btn-danger m-1"
              @click="SET_CHANGE_APPROVAL(-1)"
            >
              {{ $t("reject") }}
            </button>
          </span>
          <span v-else>
            <span
              class="badge py-1"
              :class="{
                'badge-success': user.isVerify === 1,
                'badge-danger': user.isVerify === -1,
              }"
              >{{
                user.isVerify === 1 ? $t("approved") : $t("notApproved")
              }}</span
            >
          </span>
        </span>
      </div>
    </div>
    <RemoveUser :user="user"></RemoveUser>
  </div>
</template>

<script>
import { formatter } from "gurhan/helpers/Date";
import RemoveUser from "./RemoveUser.vue";
export default {
  props: {
    user: {
      default: () => {
        return {
          fullname: "",
        };
      },
    },
  },
  data() {
    return {
      alertBox: {
        status: false,
        message: "",
        variant: "",
      },
    };
  },
  methods: {
    formatter,
    async SET_CHANGE_APPROVAL(value) {
      const response = await this.$api.put(`Users/Verify/${this.user.id}`, {
        id: this.user.id,
        verify: value,
      });

      if (response.message === "OK") {
        this.$store.dispatch("SEND_UPDATE_CUSTOMER_DETAIL", {
          form: {
            isVerify: (this.user.isVerify = value),
          },
        });
        setTimeout(() => {
          this.$router.push("/user-list");
          this.$store.commit("RESET_CUSTOMER_LIST", false);
        }, 2000);

        if (value === 1) {
          this.alertBox = {
            status: true,
            message: this.$t("approved"),
            variant: "success",
          };
        } else {
          this.alertBox = {
            status: true,
            message: "Reddedildi",
            variant: "success",
          };
        }
      } else {
        if (value === 1) {
          this.alertBox = {
            status: true,
            message: this.$t("notApproved"),
            variant: "danger",
          };
        } else {
          this.alertBox = {
            status: true,
            message: "Reddedilemedi.",
            variant: "danger",
          };
        }
      }

      setTimeout(() => {
        this.alertBox.status = false;
      }, 2000);
    },
  },
  components: { RemoveUser },
  created() {
    this.alertBox.status = false;
  },
};
</script>

<style></style>
