var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[(_vm.user.fullname)?_c('span',{staticClass:"d-none bg-primary d-md-flex avatar-text"},[_vm._v(_vm._s(_vm.user.fullname.charAt(0)))]):_c('span',{staticClass:"avatar-text"},[_c('img',{attrs:{"src":"/android-chrome-192x192.png","alt":""}})]),_c('div',{staticClass:"ml-4"},[_c('h4',[_c('i',{staticClass:"icon-user"}),_vm._v(" "+_vm._s(_vm.user.fullname)+" "),_c('img',{attrs:{"src":`https://flagicons.lipis.dev/flags/4x3/${
                _vm.user?.langCode == 'undefined'
                  ? 'tr'
                  : _vm.user.langCode?.toLowerCase() || 'tr'
              }.svg`,"title":_vm.user.langCode?.toLowerCase() || 'tr',"width":"30","alt":_vm.user.langCode}})]),_c('p',[_vm._v(_vm._s(_vm.formatter(new Date(_vm.user.createdAt))))])])]),_c('div',[_c('button',{staticClass:"btn btn-pill donate m-1",on:{"click":function($event){return _vm.$store.commit('SET_MAIL_DRAWER', {
              status: true,
              form: {
                to: _vm.user.email,
                bcc: '',
                subject: ``,
                content: ``,
                files: [],
              },
            })}}},[_vm._v(" "+_vm._s(_vm.$t("sendMessage"))+" ")]),_c('button',{staticClass:"btn btn-pill m-1",on:{"click":function($event){return _vm.$router.replace({
              query: {
                ..._vm.$route.query,
                modal: 'remove-user',
              },
            })}}},[_vm._v(" "+_vm._s(_vm.user.isActive === 1 ? _vm.user.roleId === 3 ? _vm.$t("removeInstructor") : _vm.$t("removeUser") : _vm.$t("reActiveUser"))+" ")])])]),_c('hr'),(_vm.alertBox.status)?_c('div',{staticClass:"alert mb-2",class:`alert-${_vm.alertBox.variant}`},[_vm._v(" "+_vm._s(_vm.alertBox.message)+" ")]):_vm._e(),_c('div',{staticClass:"mt-4 user-detail-header"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("email"))+": "),_c('a',{staticClass:"email-link",attrs:{"href":`mailto:${_vm.user.email}`}},[_vm._v(_vm._s(_vm.user.email))])]),_c('span',{staticClass:"border-left"},[_vm._v(" "+_vm._s(_vm.$t("phone"))+": "),_c('a',{staticClass:"tel-link",attrs:{"href":`tel:${_vm.user.phone}`}},[_vm._v(_vm._s(_vm.user.phone))])]),_vm._v(" "+_vm._s(_vm.$t("situation"))+": "),_c('span',{staticClass:"badge py-1",class:{
          'badge-success': _vm.user.isActive === 1,
          'badge-danger': _vm.user.isActive === 0,
        }},[_vm._v(_vm._s(_vm.user.isActive === 1 ? _vm.$t("active") : _vm.$t("closed")))]),_c('span',{staticClass:"border-left"},[_vm._v(" "+_vm._s(_vm.$t("approval"))+": "),(_vm.user.isVerify === 0)?_c('span',[_c('button',{staticClass:"btn btn-pill btn-success m-1",on:{"click":function($event){return _vm.SET_CHANGE_APPROVAL(1)}}},[_vm._v(" "+_vm._s(_vm.$t("approve")))]),_c('button',{staticClass:"btn btn-pill btn-danger m-1",on:{"click":function($event){return _vm.SET_CHANGE_APPROVAL(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("reject"))+" ")])]):_c('span',[_c('span',{staticClass:"badge py-1",class:{
              'badge-success': _vm.user.isVerify === 1,
              'badge-danger': _vm.user.isVerify === -1,
            }},[_vm._v(_vm._s(_vm.user.isVerify === 1 ? _vm.$t("approved") : _vm.$t("notApproved")))])])])])]),_c('RemoveUser',{attrs:{"user":_vm.user}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }