<template>
  <div class="text-center">
    <img src="/images/no-auth.svg" width="320" alt="" />
    <h5 class="mt-4">{{ $t("forbidden") }}</h5>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
