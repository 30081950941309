<template>
  <User></User>
</template>

<script>
import User from "../components/User/index.vue";
export default {
  components: { User },
};
</script>

<style></style>
